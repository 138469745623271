<template>
  <div id="app" class="d-flex flex-column min-vh-100">
    <header>
    <b-navbar id="navbar" toggleable="lg">
      <b-navbar-brand href="#">
        <img
          class="navbar-brand"
          src="@/assets/behaviour-lab-test-logo.png"
          alt-text="Behaviour Lab Test Logo"
        />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <router-link to="/" custom v-slot="{ href, route, navigate, isActive, isExactActive }">
            <b-nav-item :href="href" @click="navigate" class="square" :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
              <span class="styled-nav-link" :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                HOME
              </span>
            </b-nav-item>
          </router-link>
          <div v-if="visible()">
          <router-link to="/chart" custom v-slot="{ href, route, navigate, isActive, isExactActive }">
            <b-nav-item  :href="href" @click="navigate" class="square" :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
              <span class="styled-nav-link" :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                CHART
              </span>
            </b-nav-item>
          </router-link>
          </div>
          <router-link to="/about" custom v-slot="{ href, route, navigate, isActive, isExactActive }">
            <b-nav-item :href="href" @click="navigate" class="square" :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
              <span class="styled-nav-link" :class="[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']">
                ABOUT
              </span>
            </b-nav-item>
          </router-link>

        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav v-if="visible()" class="ml-auto right-padded">
            <b-button @click="logout" variant="dark">Sign Out</b-button>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </header>
  <content>
    <section>
      <router-view />
    </section>
  </content>
  <footer class="mt-auto">
    <p></p>
  </footer>
</div>
</template>


<script>
import store from "@/store";
export default {
  methods: {
    async logout() {
      this.$store.dispatch("logout");
    },
    visible () {
      return store.getters.isLoggedIn;
    }
  }
}
</script>


<style lang="scss">

@import "@/design";
.styled-nav-link {
  font-weight: bold;
  color: white;
  &.router-link-exact-active {
    font-weight: bold;
    color: $navbar-bg;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#navbar {
  padding: 0;
}
.right-padded {
  padding-right: 4%;
}
.navbar, .navbar-collapse {
  padding: 0px;
  background-color: $navbar-bg;
  a, .nav-link {
    color: white;
    &.router-link-exact-active {
      color: $navbar-bg;
    }
  }
  .nav-item {
    font-weight: bold;
    display: flex;
    justify-content:center;
    color: white;
    margin-left: 20px;
    margin-right: 20px;
    &.router-link-exact-active {
      background-color: $navbar-active-bg;
      color: $navbar-bg;
    }
  }
}
footer {
  padding: 20px;
  background-color: $navbar-bg;
}
.square {
  padding: 30px;
}
</style>
