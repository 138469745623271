<template>
  <div class="home">
    <div v-if="this.$store.getters.isLoggedIn">
      <h2>Hello {{ this.$store.getters.getUser.first_name }}!</h2>
      <p>You are successfully logged in.</p>
    </div>
    <div v-else>
      <LoginForm ></LoginForm>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginForm from "@/components/LoginForm.vue";

export default {
  name: "Home",
  components: {
    LoginForm
  }
};
</script>

<style>
.home{
  padding-top: 2%;
}
</style>
