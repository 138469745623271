import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { BootstrapVue } from "bootstrap-vue"; // add IconsPlugin if needed

import axios from "axios";
import VueAxios from "vue-axios";

/*
Settings these headers is crucial to get the csrf token working
Note that if the csrftoken is not visible when you type `document.cookie`
in the console, it means it is not available. The most likely reason is that
you need to set the CSRF domain name in the back-end. 
*/
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;
if (process.env.NODE_ENV !== "production") {
  axios.defaults.baseURL = "http://localhost:8000";
} else {
  axios.defaults.baseURL = "https://api.behaviourlabtest.xyz";
}


Vue.use(VueAxios, axios);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

// We load Vue at this point to be sure it's available for Charts.js
Vue.use(require("vue-moment"));

// Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
