var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column min-vh-100",attrs:{"id":"app"}},[_c('header',[_c('b-navbar',{attrs:{"id":"navbar","toggleable":"lg"}},[_c('b-navbar-brand',{attrs:{"href":"#"}},[_c('img',{staticClass:"navbar-brand",attrs:{"src":require("@/assets/behaviour-lab-test-logo.png"),"alt-text":"Behaviour Lab Test Logo"}})]),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',[_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('b-nav-item',{staticClass:"square",class:[isActive && 'router-link-active', isExactActive && 'router-link-exact-active'],attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"styled-nav-link",class:[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']},[_vm._v(" HOME ")])])]}}])}),(_vm.visible())?_c('div',[_c('router-link',{attrs:{"to":"/chart","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('b-nav-item',{staticClass:"square",class:[isActive && 'router-link-active', isExactActive && 'router-link-exact-active'],attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"styled-nav-link",class:[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']},[_vm._v(" CHART ")])])]}}],null,false,3051166333)})],1):_vm._e(),_c('router-link',{attrs:{"to":"/about","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('b-nav-item',{staticClass:"square",class:[isActive && 'router-link-active', isExactActive && 'router-link-exact-active'],attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"styled-nav-link",class:[isActive && 'router-link-active', isExactActive && 'router-link-exact-active']},[_vm._v(" ABOUT ")])])]}}])})],1),(_vm.visible())?_c('b-navbar-nav',{staticClass:"ml-auto right-padded"},[_c('b-button',{attrs:{"variant":"dark"},on:{"click":_vm.logout}},[_vm._v("Sign Out")])],1):_vm._e()],1)],1)],1),_c('content',[_c('section',[_c('router-view')],1)]),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"mt-auto"},[_c('p')])}]

export { render, staticRenderFns }