import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import router from "@/router";
const moment = require("moment");

Vue.use(Vuex);

const getDefaultState = () => {
  return {
    user: {},
    loggedIn: false,
    dateRange: {startDate: null, endDate: null}
  };
};

export default new Vuex.Store({
  strict: true,
  plugins: [createPersistedState()],
  state: getDefaultState(),
  getters: {
    isLoggedIn: state => {
      return state.loggedIn;
    },
    getUser: state => {
      return state.user;
    },
    getDateRange: state => {
      return state.dateRange;
    },
  },
  mutations: {
    SET_USER: (state, user) => {
      state.user = user;
      state.dateRange = {
        startDate: moment(user.dateRange.period_start).toDate(),
        endDate: moment(user.dateRange.period_end).toDate()
      }
      state.loggedIn = true;
    },
    RESET: state => {
      state.user = {};
      state.loggedIn = false;
    },
    UPDATE_DATE_RANGE: (state, new_val) => {
      state.dateRange = new_val;
    }
  },
  actions: {
    login: ({ commit }, { username, password }) => {
      return Vue.axios.post("auths/login/", {username, password})
      .then((response) => {
        commit("SET_USER", response.data);
        router.push("chart/");
      })
    },
    logout: ({ commit }) => {
      Vue.axios.post("auths/logout/")
      .then(() => {
        commit("RESET", "");
        router.push("/");
      });
    },
    updateDateRange: ({ commit }, { val }) => {
      const data = {
        "period_start": moment(val.startDate).format("DD/MM/YYYY"),
        "period_end": moment(val.endDate).format("DD/MM/YYYY")
      }
      return Vue.axios.patch("users/preference", data).then(() => {
        commit("UPDATE_DATE_RANGE", val)
      })
    },
  }
});
