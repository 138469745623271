import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "extrastaticpage" */ "../views/About.vue")
  },
  {
    path: "/chart",
    name: "Chart",
    component: () =>
      import(/* webpackChunkName: "chart" */ "../views/Chart.vue"),
    meta: {requiresAuth: true}
  },
  {
    path: '*',
    component: () =>
      import(/* webpackChunkName: "extrastaticpage" */ "../views/NotFound.vue"),
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
    const authenticatedUser = store.getters.isLoggedIn;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    if (requiresAuth && ! authenticatedUser) {
      next('/');
    } else {
      next();
    }
});

export default router;
