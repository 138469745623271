<template>
<div id="login-form">
  <h3>Welcome to Behaviour lab coding assessment XYZ</h3>
  <p>Please login to access your different charts.</p>
  <b-form @submit.prevent="login">
    <p class="h4 text-center mb-4">Sign in</p>
    <label for="username-field" class="grey-text">Your username</label>
    <b-form-input type="text" id="username-field" class="form-control" v-model="username" placeholder="username"/>
    <br/>
    <label for="password-field" class="grey-text">Your password</label>
    <b-form-input type="password" id="password-field" class="form-control" v-model="password" placeholder="password"/>
    <b-alert
      :show="dismissCountDown"
      fade
      variant="warning"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
      >
      {{ msg }}
    </b-alert>
    <div class="text-center mt-4">
      <b-button class="btn btn-indigo" type="submit">Login</b-button>
    </div>
  </b-form>
</div>
</template>

<script>


export default {
  name: "LoginForm",
  data() {
    return {
      username: "",
      password: "",
      msg: "",
      dismissSecs: 4,
      dismissCountDown: 0,
    };
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert() {
      this.dismissCountDown = this.dismissSecs
    },
    login() {
        const username = this.username;
        const password = this.password;
        this.$store.dispatch("login", {username, password})
        .catch((error) => {
          this.showAlert();
          this.msg = error.response.data.detail;
      });
    }
  }
};
</script>

<style>
#login-form {
  margin-top: 5%;
}
form {
  position: relative;
  z-index: 1;
  background: #ffffff;
  max-width: 360px;
  margin: 0 auto 100px;
  margin-top: 50px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
form button {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #4caf50;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
form .message a {
  color: #4caf50;
  text-decoration: none;
}
form .register-form {
  display: none;
}
</style>
